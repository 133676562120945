.cta {
  border: 1px solid gray;
  width: 50%;
  margin: auto;
  margin-top: 50px;
  padding: 50px;
  font-size: 50px;
}

.cta:hover {
  box-shadow: rgb(8, 8, 8) 7px 7px 29px 0px;
}

.ctaLink:visited {
  color: black;
}
