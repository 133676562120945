.containerloader div {
  color: black;
}

.menu {
  padding: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  text-align: center;
  margin: auto;
  width: 50%;
  margin-top: 50px;
}

.menu ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.smallForm {
  float: left;
  font-size: smaller;
}

.smallForm p {
  padding: 0;
  margin: 0;
}

@media print {
  body {
    color: #000;
    text-align: center;
    margin: 0;
    padding-inline-start: 0px;

    padding-left: 0;
    float: none !important;
    overflow: visible;
  }

  .menu {
    padding-inline-start: 0px;
    display: block;
    width: 110%;
    margin: 10px;
  }
  .mealsList div {
    float: none;
    display: block;
  }

  .mealsList div:nth-child(10n) {
    color: lime;
    background-color: #000;
    break-before: always !important;
    /* break-inside: avoid !important; */
  }
}
