.list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
}
.list button {
  padding: 2px;
  margin: 0 5px;
  flex: 1 1 0px;
}
#extras {
  flex-direction: column;
  padding: 0 25px;
}
#extras li {
  box-shadow: none;
  font-size: smaller;
  line-height: 1%;
}
ul {
  padding-inline-start: 0px;
}

.list ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 10px;
  margin: 10px;
}
.random {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
}
.list li {
  width: 200px;
  height: 250px;
  padding: 10px;
  /* margin: 10px; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.border {
  margin: 2px;
  border: 1px solid black;
}
.bar {
  background: rgb(18, 140, 221);
  color: red;
}
.mealIcon {
  font-size: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  margin: 15px;
  opacity: 80%;
}
.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

@media print {
  .list ul,
  li {
    margin: 0;
    padding: 1px;
    padding-left: 0;
    padding-inline-start: 0px;
    width: 25%;
    height: 25%;
    float: none;
    width: auto;
  }
}
