.categories {
  display: flex;
  justify-content: center;
  align-content: center;
}

.vranger {
  width: 70%;
  margin: 50px 0;
  padding: 0;
  transform: rotate(270deg);
  -moz-transform: rotate(270deg); /*do same for other browsers if required*/
}

/* .mealRange {
  display: flex;
  flex-direction: column; 
  text-align: center;
}

.checker {
  padding: 0;
  margin: 0; 
  display: flex;
  flex-direction: row;
} */
