input {
  margin: 10px;
}
.form {
  text-align: center;
  margin: auto;
  width: 50%;

  padding: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

button {
  padding: 10px;
  margin: 25px;
}
