body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .centered {
  margin: 3rem auto;
  text-align: center; 
   display: flex;
  justify-content: center;
  align-items: center;
} */
.centered {
  font-size: medium;
  line-height: 2em;
  width: 50%;
  margin: auto;
}

.helperIcon {
  position: absolute;
  font-size: 3em;
  font-weight: 1000;

  right: 5px;
  top: -50px;
  margin: 5px;
}
.helperIcon:hover {
  cursor: pointer;
}
